import styled from 'styled-components'

export const ProductCard = styled.div`

    background-color: rgba(52, 52, 52, 0.3);
    /* justify-content: flex-start; */
    /* display: flex;
    justify-content: center; */
    width: auto;
    min-width: 800px;
    height: 110px;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    /* max-height: 400px; */
    /* max-width: 300px; */
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    position:relative;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    @media screen and (max-width: 830px){
        min-width: 350px;
    }

    @media screen and (max-width: 425px){
        min-width: 300px;
        min-height: 120px;
    }

    @media screen and (max-width: 350px){
        min-width: 280px;
        min-height: 140px;
    }
    @media screen and (max-width: 280px){
        min-width: 260px;
        min-height: 130px;
    }

`

export const ProductImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 15px;
    @media screen and (max-width: 768px){
        height: 110px;
    }
`


export const ProductH2 = styled.h2`
    font-size: 1.1rem;
    justify-content: center;
    text-align: end;
    color: white;
    position: absolute;
    top: 2px;
    right: 5px;
    font-family: Arial, Helvetica, sans-serif;

    @media screen and (max-width: 768px){
        max-width: 180px;
    }
    @media screen and (max-width: 350px){
        max-width: 150px;
    }
    @media screen and (max-width: 280px){
        max-width: 100px;
    }
`

export const ProductPrice = styled.p`
    color: white;
    font-size: 16px;
    padding-top: 15px;
    padding-right: 50px;
   

    @media screen and (max-width: 480px){
        padding-right: 80px;
        padding-right: 10px;
        padding-top: 25px;
    }

    @media screen and (max-width: 320px){
        padding-right: 10px;
        padding-top: 25px;
    }

    @media screen and (max-width: 280px){
        padding-left: 50px;
        padding-top: 5px;
    }
`
export const CardDescription = styled.p`
    position: absolute;
    top: 40px;
    right: 0;
    color: gray;
    font-weight: 500;
    padding: 5px;
    text-align: end;
    max-width: 260px;
    align-items: center;
    font-size: 11px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    @media screen and (max-width: 830px){
        max-width: 210px;
    }
    @media screen and (max-width: 768px){
        max-width: 200px;
    }
    @media screen and (max-width: 420px){
        width: 175px;
    }
    @media screen and (max-width: 280px){
        width: 150px;
    }
`



// export const PriceTag = styled.div`
//     position: absolute;
//     left: 0px;
//     top: 0px;
//     z-index: 5;
//     background-color: #bf2640;//#92AD40
//     padding: 7px;
//     color: #FFFFFF;
//     font-weight: bold;
//     border-radius: 5px;
//     opacity: 0.8;
      
// `

// export const ProductH1 = styled.h1`
//     font-size: 2.5rem;
//     color: #fff;
//     margin-bottom: 64px;

//     @media screen and (max-width: 480px){
//         font-size: 2rem;
//     }
// `
