
export const MenuData = [
  [//בורגרים
    {
      id: 1,
      imageUrl:require('../assets/burgers/crispy-burger.webp'),
      nameHe: "בורגר קריסבי",
      nameAr: "بورجر كرسبي",
      price: "40",
      descriptionHe:"בורגר קרסבי, מוגש עם צ'יפס ורטבים ייחודיים של המסעדה. תוספות לבחירה: חסה, עגבנייה, מלפפון כבוש, בצל - ₪0, גבינת צ'דר ₪ 5, גבינת גאודה ₪ 5, קופסת קולסלוא  ₪ 5",
      descriptionAr:"برجر كرسبي, يقدم مع شيبس وصوصات المطعم المميزة. اضافات حسب الاختيار : خس ، بندورة، خيار مخلل ، بصل- ₪0، جبنة تشيدر ₪5، جبنة جاودا ₪5، علبة كول سلو ₪5",
      categoryHe: "בורגר",
      categoryAr:"بورجر"
    },
    {
      id: 2,
      imageUrl:require('../assets/burgers/burger2.webp'),
      nameHe: "160 - בורגר בקר",
      nameAr: "برجر عجل",
      price: "40",
      descriptionHe:"בורגר 160 גרם, מוגש עם צ'יפס ורטבים ייחודיים של המסעדה. תוספות לבחירה: חסה, עגבנייה, מלפפון כבוש, בצל - ₪0, גבינת צ'דר ₪ 5, גבינת גאודה ₪ 5, בצל מקורמל ₪ 5, פטריות מקורמל - ₪ 5",
      descriptionAr:"برجر 160 غم، يقدم مع شيبس وصوصات المطعم المميزة. اضافات حسب الاختيار : خس ، بندورة، خيار مخلل ، بصل- ₪0، جبنة تشيدر ₪5، جبنة جاودا ₪5، بصل مكرمل ₪5، فقع مكرمل - ₪5",
      categoryHe: "בורגר",
      categoryAr:"بورجر"
    },
    {
      id: 3,
      imageUrl:require('../assets/burgers/burger1.webp'),
      nameHe: "220 - בורגר בקר",
      nameAr: "برجر عجل",
      price: "50",
      descriptionHe:"בורגר 220 גרם, מוגש עם צ'יפס ורטבים ייחודיים של המסעדה. תוספות לבחירה: חסה, עגבנייה, מלפפון כבוש, בצל - ₪0, גבינת צ'דר ₪ 5, גבינת גאודה ₪ 5, בצל מקורמל ₪ 5, פטריות מקורמל - ₪ 5",
      descriptionAr:"برجر 220 غم، يقدم مع شيبس وصوصات المطعم المميزة. اضافات حسب الاختيار : خس ، بندورة، خيار مخلل ، بصل- ₪0، جبنة تشيدر ₪5، جبنة جاودا ₪5، بصل مكرمل ₪5، فقع مكرمل - ₪5",
      categoryHe: "בורגר",
      categoryAr:"بورجر"
    },
    {
      id: 4,
      imageUrl:require('../assets/burgers/burger1.webp'),
      nameHe: "330 - בורגר בקר",
      nameAr: "برجر عجل",
      price: "60",
      descriptionHe:"בורגר 330 גרם, מוגש עם צ'יפס ורטבים ייחודיים של המסעדה. תוספות לבחירה: חסה, עגבנייה, מלפפון כבוש, בצל - ₪0, גבינת צ'דר ₪ 5, גבינת גאודה ₪ 5, בצל מקורמל ₪ 5, פלפל מקורמל - ₪ 5",
      descriptionAr:"برجر 330 غم، يقدم مع شيبس وصوصات المطعم المميزة. اضافات حسب الاختيار : خس ، بندورة، خيار مخلل ، بصل- ₪0، جبنة تشيدر ₪5، جبنة جاودا ₪5، بصل مكرمل ₪5، فقع مكرمل - ₪5",
      categoryHe: "בורגר",
      categoryAr:"بورجر"
    },
    // {
    //   id: 5,
    //   imageUrl:require('../assets/burgers/losy.webp'),
    //   nameHe: "בורגר ג'וסי לוסי",
    //   nameAr: "برجر جوسي لوسي",
    //   price: "60",
    //   descriptionHe:"בורגר 220 גרם ממולא גבינה עם הבשר, מגיע עם שכבת גבינה בין נתחי הבשר וגבינה מעל, תוספת לבחירה: חסה, עגבניה, מלפפון חמוץ, בצל",
    //   descriptionAr:"برجر 220 جرام محشو بالجبن مع اللحم، يأتي مع طبقة من الجبن بين قطع اللحم وفوقها الجبن، حسب الاختيار: خس، طماطم، مخلل، بصل",
    //   categoryHe: "בורגר",
    //   categoryAr:"بورجر"
    // },
    // {
    //   id: 6,
    //   imageUrl:require('../assets/burgers/burger.webp'),
    //   nameHe: "אמריקן בייט בורגר",
    //   nameAr: "امريكان بورجر",
    //   price: "55",
    //   descriptionHe:"בורגר 220 גרם, מגיע עם רוטב הבית, טבעות בצל ובצל מקורמל מעל",
    //   descriptionAr:"برجر 220 جرام، يأتي مع صلصة, حلقات البصل والبصل المقرمل في الأعلى",
    //   categoryHe: "בורגר",
    //   categoryAr:"بورجر"
    // },
    // {
    //   id: 7,
    //   imageUrl:require('../assets/burgers/burger.webp'),
    //   nameHe: "אמריקן בייט ספייסי בורגר",
    //   nameAr: "امريكان بورجر حار",
    //   price: "55",
    //   descriptionHe:"בורגר 220 גרם, חריף מגיע עם רוטב הבית, טבעות בצל ובצל מקורמל מעל",
    //   descriptionAr:"برجر 220 جرام، حار يأتي مع صلصة, حلقات البصل والبصل المقرمل في الأعلى",
    //   categoryHe: "בורגר",
    //   categoryAr:"بورجر"
    // },
  
  ],
  [//מנות קרספי אישית
    {
    id: 1,
    imageUrl:require('../assets/crispy-chicken/crispy1.webp'),
    nameHe: "פילה עוף קריספי - 6 חתיכות",
    nameAr: "دجاج فيليه كرسبي 6 قطع",
    price: "55",
    descriptionHe:"פילה עוף קריספי 6 חתיכות, הארוחה כוללת: צ'יפס, רטבים, לסלט קולסלאו וחתיכת לחם, תוספות: קופסת אורז 15₪",
    descriptionAr:"وجبة عائلية امريكان بايت ، عبارة عن 6 قطعة دجاج فيليه، تقدم مع الصلصات ، سلطة كول سلو ، الشيبس و 2 لحمنيوت ",
    categoryHe: "קריספי",
    categoryAr:"كريسبي"
  },
  {
    id: 2,
    imageUrl:require('../assets/crispy-chicken/crispy4.webp'),
    nameHe: "כנפיים פריכות 8 חתיכות",
    nameAr: "أجنحة مقرمشة 8 قطع",
    price: "50",
    descriptionHe:"ארוחת כנפיים 8 חתיכות, הארוחה כוללת: צ'יפס, רטבים, לסלט קולסלאו וחתיכת לחם, תוספות: קופסת אורז 15₪",
    descriptionAr:"وجبة كريسبي اجنحة 8 قطع كريسبي، الوجبة تشمل: شيبس ، صوصات ، سلطة كولسلو وقطعة خبز, اضافات: علبة رز ₪15",
    categoryHe: "קריספי",
    categoryAr:"كريسبي"
  },
  {
    id: 3,
    imageUrl:require('../assets/crispy-chicken/crispy2.webp'),
    nameHe: "פילה עוף קריספי - 6 חתיכות",
    nameAr: "وجبة كريسبي افخاد - 6 قطع",
    price: "60",    
    descriptionHe:"ארוחת קריסבי ירכיים 6 חתיכות, הארוחה כוללת: צ'יפס, רטבים, לסלט קולסלאו וחתיכת לחם, תוספות: קופסת אורז 15₪",
    descriptionAr:"وجبة كريسبي افخاد دجاج 6 قطع كريسبي، الوجبة تشمل: شيبس ، صوصات ، سلطة كولسلو وقطعة خبز, اضافات: علبة رز ₪15",
    categoryHe: "קריספי",
    categoryAr:"كريسبي"
  },
  {
    id: 4,
    imageUrl:require('../assets/crispy-chicken/crispy1.webp'),
    nameHe: "מקס פילה פריך וכנפיים - 6 חתיכות",
    nameAr: "كريسبي ميكس فيليه واجنحة 6 قطع",
    price: "50",
    descriptionHe:"פילה עוף קריספי 3 כנפיים ו 3 פילה כוף, הארוחה כוללת: צ'יפס, רטבים, לסלט קולסלאו וחתיכת לחם, תוספות: קופסת אורז 15₪",
    descriptionAr:"وجبة كريسبي فيليه دجاج 3 اجنحة و 3 فيلة دجاج، الوجبة تشمل: شيبس ، صوصات ، سلطة كولسلو وقطعة خبز, اضافات: علبة رز ₪15",
    categoryHe: "קריספי",
    categoryAr:"كريسبي"
  },
  {
    id: 5,
    imageUrl:require('../assets/crispy-chicken/crispy3.webp'),
    nameHe: "מקס כנפיים וירכיים - 6 חתיכות",
    nameAr: "مكس اجنحة وافخاد 6 قطع",
    price: "55",
    descriptionHe:"מנה של 8 חתיכות מיקס ענפיים ופילה עוף בציפוי הבית מוגשת עם רוטבים, סלט קולסלו צ'יפס ולחמניה",
    descriptionAr:"وجبة كريسبي مكس اجنحة وافخاد 3 اجنحة و 3 افخاد، الوجبة تشمل: شيبس ، صوصات ، سلطة كولسلو وقطعة خبز, اضافات: علبة رز ₪15",
    categoryHe: "קריספי",
    categoryAr:"كريسبي"
  },
  ],
  [//מנות קרספי זוגית
    {
      id: 1,
      imageUrl:require('../assets/crispy-chicken/crispy1.webp'),
      nameHe: "פילה עוף קריספי - 12 חתיכות",
      nameAr: "دجاج فيليه كرسبي 12 قطع",
      price: "100",
      descriptionHe:"פילה עוף קריספי 12 חתיכות, הארוחה כוללת: צ'יפס, רטבים, 2 וסלט קולסלאו ו 2 חתיכת לחם, תוספות: קופסת אורז 15₪",
      descriptionAr:"وجبة كريسبي فيليه دجاج 12 قطع كريسبي، الوجبة تشمل: شيبس ، صوصات ،2 سلطة كولسلو وقطعتين خبز, اضافات: علبة رز ₪15",
      categoryHe: "קריספי",
      categoryAr:"كريسبي"
    },
    {
      id: 2,
      imageUrl:require('../assets/crispy-chicken/crispy4.webp'),
      nameHe: "כנפיים פריכות 16 חתיכות",
      nameAr: "أجنحة مقرمشة 16 قطع",
      price: "80",
      descriptionHe:"ארוחת כנפיים 16 חתיכות, הארוחה כוללת: צ'יפס, רטבים, 2 וסלט קולסלאו ו 2 חתיכת לחם, תוספות: קופסת אורז 15₪",
      descriptionAr:"وجبة كريسبي اجنحة 16 قطع كريسبي، الوجبة تشمل: شيبس ، صوصات ،2 سلطة كولسلو وقطعتين خبز, اضافات: علبة رز ₪15",
      categoryHe: "קריספי",
      categoryAr:"كريسبي"
    },
    {
      id: 3,
      imageUrl:require('../assets/crispy-chicken/crispy2.webp'),
      nameHe: "פילה עוף קריספי - 12 חתיכות",
      nameAr: "وجبة كريسبي افخاد - 12 قطع",
      price: "110",    
      descriptionHe:"ארוחת קריסבי ירכיים 12 חתיכות, הארוחה כוללת: צ'יפס, רטבים, 2 וסלט קולסלאו ו 2 חתיכת לחם, תוספות: קופסת אורז 15₪",
      descriptionAr:"وجبة كريسبي افخاد دجاج 12 قطع كريسبي، الوجبة تشمل: شيبس ، صوصات ،2 سلطة كولسلو وقطعتين خبز, اضافات: علبة رز ₪15",
      categoryHe: "קריספי",
      categoryAr:"كريسبي"
    },
    {
      id: 4,
      imageUrl:require('../assets/crispy-chicken/crispy1.webp'),
      nameHe: "מקס פילה פריך וכנפיים - 14 חתיכות",
      nameAr: "كريسبي ميكس فيليه واجنحة 14 قطع",
      price: "85",
      descriptionHe:"פילה עוף קריספי 7 כנפיים ו 7 פילה כוף, הארוחה כוללת: צ'יפס, רטבים, 2 וסלט קולסלאו ו 2 חתיכת לחם, תוספות: קופסת אורז 15₪",
      descriptionAr:"وجبة كريسبي فيليه دجاج 7 اجنحة و 7 فيلة دجاج، الوجبة تشمل: شيبس ، صوصات ،2 سلطة كولسلو وقطعتين خبز, اضافات: علبة رز ₪15",
      categoryHe: "קריספי",
      categoryAr:"كريسبي"
    },
    {
      id: 5,
      imageUrl:require('../assets/crispy-chicken/crispy3.webp'),
      nameHe: "מקס כנפיים וירכיים - 12 חתיכות",
      nameAr: "مكس اجنحة وافخاد 12 قطع",
      price: "100",
      descriptionHe:"מקס כנפיים וירכיים 6 כנפיים ו 6 ירכיים, הארוחה כוללת: צ'יפס, רטבים, 2 וסלט קולסלאו ו 2 חתיכת לחם, תוספות: קופסת אורז 15₪",
      descriptionAr:"وجبة كريسبي مكس اجنحة وافخاد 6 اجنحة و 6 افخاد، الوجبة تشمل: شيبس ، صوصات ،2 سلطة كولسلو وقطعتين خبز, اضافات: علبة رز ₪15",
      categoryHe: "קריספי",
      categoryAr:"كريسبي"
    },
    ],
  [//מנות קרספי משפחתי
    {
      id: 1,
      imageUrl:require('../assets/crispy-chicken/crispy1.webp'),
      nameHe: "פילה עוף קריספי - 20 חתיכות",
      nameAr: "دجاج فيليه كرسبي 20 قطع",
      price: "140",
      descriptionHe:"פילה עוף קריספי 20 חתיכות, הארוחה כוללת: צ'יפס, רטבים, 2 וסלט קולסלאו ו 2 חתיכת לחם, תוספות: קופסת אורז 15₪",
      descriptionAr:"وجبة كريسبي فيليه دجاج 20 قطع كريسبي، الوجبة تشمل: شيبس ، صوصات ،2 سلطة كولسلو وقطعتين خبز, اضافات: علبة رز ₪15",
      categoryHe: "קריספי",
      categoryAr:"كريسبي"
    },
    {
      id: 2,
      imageUrl:require('../assets/crispy-chicken/crispy4.webp'),
      nameHe: "כנפיים פריכות 25 חתיכות",
      nameAr: "أجنحة مقرمشة 25 قطع",
      price: "100",
      descriptionHe:"ארוחת כנפיים 25 חתיכות, הארוחה כוללת: צ'יפס, רטבים, 2 וסלט קולסלאו ו 2 חתיכת לחם, תוספות: קופסת אורז 15₪",
      descriptionAr:"وجبة كريسبي اجنحة 25 قطع كريسبي، الوجبة تشمل: شيبس ، صوصات ،2 سلطة كولسلو وقطعتين خبز, اضافات: علبة رز ₪15",
      categoryHe: "קריספי",
      categoryAr:"كريسبي"
    },
    {
      id: 3,
      imageUrl:require('../assets/crispy-chicken/crispy2.webp'),
      nameHe: "פילה עוף קריספי - 20 חתיכות",
      nameAr: "وجبة كريسبي افخاد - 20 قطع",
      price: "170",
      descriptionHe:"ארוחת קריסבי ירכיים 20 חתיכות, הארוחה כוללת: צ'יפס, רטבים, 2 וסלט קולסלאו ו 2 חתיכת לחם, תוספות: קופסת אורז 15₪",
      descriptionAr:"وجبة كريسبي افخاد دجاج 20 قطع كريسبي، الوجبة تشمل: شيبس ، صوصات ،2 سلطة كولسلو وقطعتين خبز, اضافات: علبة رز ₪15",
      categoryHe: "קריספי",
      categoryAr:"كريسبي"
    },
    {
      id: 4,
      imageUrl:require('../assets/crispy-chicken/crispy1.webp'),
      nameHe: "מקס פילה פריך וכנפיים - 20 חתיכות",
      nameAr: "كريسبي ميكس فيليه واجنحة 20 قطع",
      price: "110",
      descriptionHe:"פילה עוף קריספי 10 כנפיים ו 10 פילה כוף, הארוחה כוללת: צ'יפס, רטבים, 2 וסלט קולסלאו ו 2 חתיכת לחם, תוספות: קופסת אורז 15₪",
      descriptionAr:"وجبة كريسبي فيليه دجاج 10 اجنحة و 10 فيلة دجاج، الوجبة تشمل: شيبس ، صوصات ،2 سلطة كولسلو وقطعتين خبز, اضافات: علبة رز ₪15",
      categoryHe: "קריספי",
      categoryAr:"كريسبي"
    },
    {
      id: 5,
      imageUrl:require('../assets/crispy-chicken/crispy3.webp'),
      nameHe: "מקס כנפיים וירכיים - 20 חתיכות",
      nameAr: "مكس اجنحة وافخاد 20 قطع",
      price: "140",
      descriptionHe:"מקס כנפיים וירכיים 10 כנפיים ו 10 ירכיים, הארוחה כוללת: צ'יפס, רטבים, 2 וסלט קולסלאו ו 2 חתיכת לחם, תוספות: קופסת אורז 15₪",
      descriptionAr:"وجبة كريسبي مكس اجنحة وافخاد 10 اجنحة و 10 افخاد، الوجبة تشمل: شيبس ، صوصات ،2 سلطة كولسلو وقطعتين خبز, اضافات: علبة رز ₪15",
      categoryHe: "קריספי",
      categoryAr:"كريسبي"
    },
    ],
  
  [//טורטיות
    {
    id: 1,
    imageUrl:require('../assets/tortilla/crispy.webp'),
    nameHe: "טורטיה קריספי צ'יקן",
    nameAr: "تورتيا كريسبي تشيكن",
    price: "40",
    descriptionHe:"טורטיה קריספי עם תוספות לבחירה, חסה, עגבניות, מלפפון חמוץ ובצל. תוספות לבחירה: צ'יפס - ₪5 , גבינת גאודה ₪5, סלט קולסלאו - ₪5",
    descriptionAr:"تورتيا كرسبي مع إضافات حسب اختيارك، خس، طماطم، مخلل وبصل. الإضافات للاختيار من بينها: رقائق البطاطس - 5 شيكل، جبنة جودة 5 شيكل، سلطة كولسلو - 5 شيكل",
    categoryHe: "טורטיות",
    categoryAr:"تورتيوت"
  },
  {
    id: 2,
    imageUrl:require('../assets/tortilla/chicken.webp'),
    nameHe: "טורטיה חזה",
    nameAr: "تورتيا دجاج",
    price: "40",
    descriptionHe:"טורטיה חזה עם תוספות לבחירה, חסה, עגבניות, מלפפון חמוץ ובצל. תוספות לבחירה: צ'יפס - ₪5 , גבינת גאודה ₪5, סלט קולסלאו - ₪5",
    descriptionAr:"تورتيا دجاج مع إضافات حسب اختيارك، خس، طماطم، مخلل وبصل. الإضافات للاختيار من بينها: رقائق البطاطس - 5 شيكل، جبنة جودة 5 شيكل، سلطة كولسلو - 5 شيكل",
    categoryHe: "טורטיות",
    categoryAr:"تورتيوت"
  },
  
  ],

  [//ארוחות ביניים
    {
      id: 1,
      imageUrl:require('../assets/appetizers/apperizers3.webp'),
      nameHe: "צ׳יפס בלאגן - מומלץ",
      nameAr: "تشيبس بلاجان",
      price: "35",
      descriptionHe:"מנת צ׳יפס קריספי מתובל , שכבת גבינה , שכבת רוטבים מיוחדים , פלפל חלפיניו ובצל ירוק עם חתיכת קריספי חתוכה מעל",
      descriptionAr:"قطعة من رقائق البطاطس المقرمشة المتبلة، طبقة من الجبن، طبقة من الصلصات الخاصة، الفلفل الرومي و البصل الأخضر مع قطعة كريسبي مقرمشة مقطعه",
      categoryHe: "מנות פתיחה",
      categoryAr: "المقبلات"
    },
    {
      id: 2,
      imageUrl:require('../assets/appetizers/apperizers6.webp'),
      nameHe: "מק אנד צ'יז",
      nameAr: "ماك اند تشيز",
      price: "30",
      descriptionHe:"כדורי תפוח אדמה פירה מצופות בציפוי פירורי לחם",
      descriptionAr:"كرات البطاطس المهروسة المغطاة بطبقة من فتات الخبز",
      categoryHe: "מנות פתיחה",
      categoryAr: "المقبلات"
    },
    {
      id: 3,
      imageUrl:require('../assets/appetizers/apperizers2.webp'),
      nameHe: "צלחת אורז",
      nameAr: "صحن ارز",
      price: "15",
      descriptionHe:"צלחת אורז",
      descriptionAr:"صحن ارز",
      categoryHe: "מנות פתיחה",
      categoryAr: "المقبلات"
    },
    {
    id: 4,
    imageUrl:require('../assets/appetizers/apperizers1.webp'),
    nameHe: "אצבעות מוצרלה",
    nameAr: "أصابع الموزاريلا",
    price: "30",
    descriptionHe:"שבעה אצבעות שניצל ממולאות באצבעות גבינת מוצרלה",
    descriptionAr:"سبع أصابع شنيتزل محشوة بأصابع جبنة الموتزاريلا",
    categoryHe: "מנות פתיחה",
    categoryAr: "المقبلات"
  },
  {
    id: 5,
    imageUrl:require('../assets/appetizers/apperizers4.webp'),
    nameHe: "כדורי פירה",
    nameAr: "كرات البطاطس المهروسة",
    price: "15",
    descriptionHe:"שמונה כדורי תפוח אדמה פירה מצופות בציפוי פירורי לחם",
    descriptionAr:"ثماني كرات البطاطس المهروسة المغطاة بطبقة من فتات الخبز",
    categoryHe: "מנות פתיחה",
    categoryAr: "المقبلات"
  },
  {
    id: 6,
    imageUrl:require('../assets/appetizers/apperizers5.webp'),
    nameHe: "טבעות בצל",
    nameAr: "حلقات بصل",
    price: "15",
    descriptionHe:"שמונה חתיכות של טבעות בצל",
    descriptionAr:"ثماني قطع من حلقات البصل",
    categoryHe: "מנות פתיחה",
    categoryAr: "المقبلات"
  },
  // {
  //   id: 3,
  //   imageUrl:require('../assets/appetizers/apperizers2.webp'),
  //   nameHe: "נאגטס עוף",
  //   nameAr: "ناجتس دجاج",
  //   price: "35",
  //   descriptionHe:"שישה חתיכות נאגטס עוף מצופות בציפוי הבית מוגשות עם רוטב שום לימון חם",
  //   descriptionAr:"ست قطع من ناجتس الدجاج تقدم مع صلصة الليمون والثوم الحارة",
  //   categoryHe: "מנות פתיחה",
  //   categoryAr: "المقبلات"
  // },
  // {
  //   id: 4,
  //   imageUrl:require('../assets/appetizers/apperizers4.webp'),
  //   nameHe: "נאגטס גבינת חלומי",
  //   nameAr: "ناجتس جبنة حلومي",
  //   price: "35",
  //   descriptionHe:"שישה חתיכות גבינת חלומי מצופות בציפוי הבית מוגשות עם רוטב צ׳ילי",
  //   descriptionAr:"ست قطع من جبنة حلومي تقدم مع صلصة الفلفل الحار",
  //   categoryHe: "מנות פתיחה",
  //   categoryAr: "المقبلات"
  // },
  ],

  [//סלטים 
  {
    id: 1,
    imageUrl:require('../assets/salads/salad2.webp'),
    nameHe: "סלט פתוש",
    nameAr: "سلطة فتوش",
    price: "35 / 25",
    descriptionHe:"סלט פתוש גדול / זוגי",
    descriptionAr:"سلطة فتوش كبير / زوجية",
    categoryHe: "סלטים",
    categoryAr:"سلطات"
  },
  {
    id: 2,
    imageUrl:require('../assets/salads/salad4.webp'),
    nameHe: "סלט סיזר",
    nameAr: "سلطة قيصر",
    price: "30 / 40",
    descriptionHe:"סלט סיזר גדול / זוגי",
    descriptionAr:"سلطة قيصر كبير / زوجية",
    categoryHe: "סלטים",
    categoryAr:"سلطات"
  },
  {
    id: 3,
    imageUrl:require('../assets/salads/salad1.webp'),
    nameHe: "סלט ערבי",
    nameAr: "سلطة عربية",
    price: "25 / 35",
    descriptionHe:"סלט ערבי גדול / זוגי",
    descriptionAr:"سلطة عربية كبير / زوجية",
    categoryHe: "סלטים",
    categoryAr:"سلطات"
  },
  {
    id: 4,
    imageUrl:require('../assets/salads/salad4.webp'),
    nameHe: "סלט ירקות עם קריסבי",
    nameAr: "سلطة خضار مع كرسبي",
    price: "30 / 40",
    descriptionHe:"סלט ירקות עם קריסבי זוגי / משפחתי",
    descriptionAr:"سلطة خضار مع كرسبي زوجية / العائلية",
    categoryHe: "סלטים",
    categoryAr:"سلطات"
  }
  // {
  //   id: 1,
  //   imageUrl:require('../assets/salads/salad1.webp'),
  //   nameHe: "סלט תבולה",
  //   nameAr: "سلطة تبوله",
  //   price: "30",
  //   descriptionHe:"פורוזליה, נענע בצל ירוק ובורגול דק, עגבניות לבחירה",
  //   descriptionAr:"بقدونس، نعناع، ​​بصل أخضر وبرغل رقيق، طماطم حسب الاختيار",
  //   categoryHe: "סלטים",
  //   categoryAr:"سلطات"
  // }, 
  // {
  //   id: 3,
  //   imageUrl:require('../assets/salads/salad3.webp'),
  //   nameHe: "סלט חלומי",
  //   nameAr: "سلطة حلومي",
  //   price: "35",
  //   descriptionHe:"סלט ירקות עם רוטב ויניגרט וגבינת חלומי מעל",
  //   descriptionAr:"سلطة خضار مع صلصة الخل وجبنة الحلومي ",
  //   categoryHe: "סלטים",
  //   categoryAr:"سلطات"
  // },
  // {
  //   id: 4,
  //   imageUrl:require('../assets/salads/salad4.webp'),
  //   nameHe: "סלט קיסר",
  //   nameAr: "سلطة كيسار",
  //   price: "35",
  //   descriptionHe:"סלט ירקות עם רוטב קיסר וחתיכות פילה עוף מעל",
  //   descriptionAr:"سلطة خضار مع صلصة سيزر و قطع من فيليه الدجاج",
  //   categoryHe: "סלטים",
  //   categoryAr:"سلطات"
  // },
  ],

  
];
