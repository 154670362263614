import React, { useState } from "react";
import { HeroContainer, HeroBg, ImageBg } from "./HeroSectionStyle";

import image1 from "../../assets/swiper/22.webp";
import image2 from "../../assets/swiper/33.webp";
import image3 from "../../assets/swiper/44.webp";
import image4 from "../../assets/swiper/55.webp";
import "./heroStyle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
const HeroSection = () => {
  return (
    <HeroContainer id="home">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={image1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image2} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image3} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image4} />
        </SwiperSlide>
      </Swiper>
    </HeroContainer>
  );
};

export default HeroSection;

{
  /* <HeroContent>
        <HeroH1>جربها مرة، بتتمناها كل مرة</HeroH1>
        <HeroP>
            Spend on something which you won’t regret, like our Food!
        </HeroP>
        <HeroBtnWrapper>
            <Button
                onMouseEnter={onHover}
                onMouseLeave={onHover}
                primary= 'true'
                dark='true'
                to="menu"
                >{language === "Ar" ? "قائمة الطعام" : "תפריט"}
                {hover ? <ArrowForward/> : <ArrowRight/>}</Button>
            <Button
                onMouseEnter={onHover}
                onMouseLeave={onHover}
                primary= 'true'
                dark='true'
                to="menu"
                >{language === "Ar" ? "اتصل بالمطعم" : "תתקשר למסעדה"}
                {hover ? <ArrowForward/> : <ArrowRight/>}</Button>
        </HeroBtnWrapper>
    </HeroContent> */
}
