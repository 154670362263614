import React, { useState } from 'react'
import {MenuContainer,MenuWrapper, MenuCard, MenuIcon, MenuH2, MenuH1,MenuH2Container, SectionLine} from './MenuStyle.js'
import Icon1 from '../../assets/crispy-chicken/crispy4.webp'
import Icon2 from '../../assets/burgers/burger1.webp'
import Icon3 from '../../assets/tortilla/crispy.webp'
import Icon4 from '../../assets/appetizers/apperizers3.webp'
import Icon6 from '../../assets/salads/salad2.webp'



const Menu = ({setMenuList, setSelectedSubMenu}) => {
  const language = localStorage.getItem("language");
  return (
    <MenuContainer id="menu">
      <MenuH1>{language === "He" ? "תפריט המסעדה": "قائمة الطعام"}</MenuH1>
      <SectionLine></SectionLine>
      <MenuWrapper>

        <MenuCard onClick={() => setSelectedSubMenu(true)}>
          <MenuH2Container><MenuH2>{language === "He" ? "קריספי" : "كريسبي"}</MenuH2></MenuH2Container>
          <MenuIcon  src={Icon1}/>
          {/* <MenuP>Testing this p in this product now!</MenuP> */}
        </MenuCard>
        <MenuCard onClick={() => {setMenuList(0); setSelectedSubMenu(false)} }>
          <MenuH2Container><MenuH2>{language === "He" ? "בורגר" : "برجر"}</MenuH2></MenuH2Container>
          <MenuIcon  src={Icon2}/>
          {/* <MenuP>Testing this p in this product now!</MenuP> */}
        </MenuCard>
        <MenuCard onClick={() => {setMenuList(4); setSelectedSubMenu(false)}}>
            <MenuH2Container><MenuH2>{language === "He" ? "טורטיה" : "تورتيا"}</MenuH2></MenuH2Container>
            <MenuIcon src={Icon3}/>
            {/* <MenuP>Testing this p in this product now!</MenuP> */}
          </MenuCard>
          <MenuCard onClick={() => {setMenuList(5); setSelectedSubMenu(false)}}>
            <MenuH2Container><MenuH2>{language === "He" ? "ארוחות ביניים" : "وجبات خفيفة"}</MenuH2></MenuH2Container>
            <MenuIcon src={Icon4}/>
          </MenuCard>
        <MenuCard onClick={() => {setMenuList(6); setSelectedSubMenu(false)}}>
         <MenuH2Container><MenuH2>{language === "He" ? "סלטים" : "سلطات"}</MenuH2></MenuH2Container>
          <MenuIcon src={Icon6}/>
        </MenuCard>
      </MenuWrapper>
      <SectionLine></SectionLine>
    </MenuContainer>
  );
}

export default Menu