import React from 'react'
import { FaPhoneSquare } from "react-icons/fa";
import { SideBarContainer, Icon, CloseIcon, SideBarWrapper, SideBarMenu, SideBarLink, PhoneIcon} from './SideBarStyle'

const SideBar = ({isOpen, toggle}) => {
  const language = localStorage.getItem("language");
  return (
    <SideBarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon/>
        </Icon>
        <PhoneIcon onClick={toggle}>
        <a href="tel:+9720524439922"><FaPhoneSquare color='white'/></a>
        </PhoneIcon>
        <SideBarWrapper>
            <SideBarMenu>
                <SideBarLink to="home" onClick={toggle}>{language === "He" ? "בית": "الرئيسيه"}</SideBarLink>
                <SideBarLink to="menu" onClick={toggle}>{language === "He" ? "תפריט המסעדה": "قائمة الطعام"}</SideBarLink>
                <SideBarLink to="About" onClick={toggle}>{language === "He" ? "מידע": "معلومات"}</SideBarLink>
            </SideBarMenu>
        </SideBarWrapper>
    </SideBarContainer>
  )
}

export default SideBar