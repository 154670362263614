import React, { useState } from 'react';
import { MenuContainer, MenuWrapper, MenuCard, MenuH2, MenuH2Container, SectionLine } from './SubMenuStyle.js';
import { Fade } from "react-awesome-reveal";

const SubMenu = ({ setMenuList }) => {
  const [activeCard, setActiveCard] = useState(null); // State to track the active card
  const language = localStorage.getItem("language");

  const handleCardClick = (cardId) => {
    setActiveCard(cardId); // Set the clicked card as active
    setMenuList(cardId); // Update menu list based on clicked card
  };

  return (
    <MenuContainer>
      <Fade>
        <MenuWrapper>
          <MenuCard
            onClick={() => handleCardClick(1)}
            isActive={activeCard === 1}
          >
            <MenuH2Container>
              <MenuH2>{language === "He" ? "אישית" : "شخصي"}</MenuH2>
            </MenuH2Container>
          </MenuCard>
          <MenuCard
            onClick={() => handleCardClick(2)}
            isActive={activeCard === 2}
          >
            <MenuH2Container>
              <MenuH2>{language === "He" ? "זוגית" : "زوجية"}</MenuH2>
            </MenuH2Container>
          </MenuCard>
          <MenuCard
            onClick={() => handleCardClick(3)}
            isActive={activeCard === 3}
          >
            <MenuH2Container>
              <MenuH2>{language === "He" ? "משפחתית" : "عائلية"}</MenuH2>
            </MenuH2Container>
          </MenuCard>
        </MenuWrapper>
      </Fade>
      <SectionLine></SectionLine>
    </MenuContainer>
  );
}

export default SubMenu;
