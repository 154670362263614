import React, {useEffect, useState} from 'react'
import Menu from '../components/Menu/Menu';
import HeroSection from '../components/HeroSection';
import NavBar from '../components/NavBar'
import SideBar from '../components/SideBar';
import Loader from '../components/Loader/Loader';
import MenuList from '../components/Menu/MenuList/MenuList'
import SubMenu from '../components/Menu/SubMenu'
import Footer from '../components/Footer';

const Home = () => {

const [isOpen, setIsOpen] = useState(false);
const [loading, setLoading] = useState(false);
const [language, setLanguage] = useState("")
const [menuList, setMenuList] = useState(0);
const [selectedSubMenu, setSelectedSubMenu] = useState(false);

const toggle = () => setIsOpen(!isOpen);

const checkLangauge = () => {
  if(localStorage.getItem('langauge') === null){
    setLanguage("Ar");
    localStorage.setItem('language', "Ar");
  }
}
const switchLanguage = () => {
  if(language === "He"){
    setLanguage("Ar");
    localStorage.setItem('language', "Ar");
  } else {
    setLanguage("He")
    localStorage.setItem('language', "He");
  }
}

useEffect(() => {
  setLoading(true);
  checkLangauge();
  setTimeout(() => {
    setLoading(false)
  }, 2500)
},[])

  return (
    <>
     <SideBar isOpen={isOpen} toggle={toggle} switchLanguage= {switchLanguage}/>
      <NavBar toggle={toggle} switchLanguage= {switchLanguage}/> 
      <HeroSection/>
      <Menu setMenuList={setMenuList} setSelectedSubMenu={setSelectedSubMenu}/>
      {selectedSubMenu ? <SubMenu setMenuList={setMenuList}/> : <></>}
      <MenuList menuList={menuList}/>
      {loading ? <Loader/> : <></> }
      <Footer/>
    </>
  )
}

export default Home